import React, { useState, useEffect } from "react";
function WithdrawNotice() {
      const [userData, setUserData] = useState({
            username: '',
            vip_coin: 0,
            name: '',
            phone: '',
            email: '',
            created_at: '',
            dofb: '',
        });
        const [isEditingBirthday, setIsEditingBirthday] = useState(false);
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');
            if (token && remoteId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        const formattedDate = data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : 'N/A';
    
                        setUserData({
                            username: data.username || 'Unknown User',
                            vip_coin: data.vip_coin || 0,
                            name: data.name || 'N/A',
                            phone: data.phone || '',
                            email: data.email || '',
                            created_at: formattedDate,
                            dofb: data.dofb || '',
                        });
    
                        // Automatically enable birthday editing if dofb is null or empty
                        if (!data.dofb) {
                            setIsEditingBirthday(true);
                        }
                       
                    } else {
                        console.error('Failed to fetch user data');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
    
     return ( 
       <>
       {isEditingBirthday && (
         <div className="ng-trigger ng-trigger-slideAnimation ng-star-inserted" style={{}}>
            <div className="tips-info verify-tips tips-info-toggle ng-star-inserted" style={{}}>
                <div className="title-box">
                    <h5>
                        <i className="tips-icon" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-tips-type02.svg?v=1729676340443")'}} />
                        <span>Please complete the verification below before you proceed with the withdrawal request.</span>
                    </h5>
                    <div className="toggle-btn ng-star-inserted" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")'}} />
                </div>
                <ol className="tips-info-block active">
                    <li className="personal-info ng-star-inserted">
                        <a className="ng-star-inserted">
                            <label>Personal Info</label>
                            <ul>
                                <li className="ng-star-inserted">Birthday</li>
                            </ul>
                        </a>
                    </li>
                    <li className="contact-info ng-star-inserted">
                        <a className="ng-star-inserted">
                            <label>Contact Info</label>
                            <ul>
                                <li className="ng-star-inserted">Phone Number</li>
                            </ul>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
       )}
       </>
      ); 
      } 
     export default WithdrawNotice;