import React, { useState, useEffect } from "react";
import DepositNotice from "../../notice/mobile/DepositNotice";
import { Link, useNavigate } from "react-router-dom";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal } from "react-bootstrap"; 

function MDeposit() {
  const [activeItem, setActiveItem] = useState(null);
  const [activeAmount, setActiveAmount] = useState(""); // Selected deposit amount
  const [accountNumber, setAccountNumber] = useState(""); // Sender account number
  const [transactionId, setTransactionId] = useState(""); // TRX ID
  const [items, setItems] = useState([]); // Payment methods
  const [promotions, setPromotions] = useState([]); // Promotions data
  const [selectedPromotion, setSelectedPromotion] = useState(null); // Selected promotion
  const [isLoading, setIsLoading] = useState(false); // Loading state for API
  const [paymentMethod, setPaymentMethod] = useState(null); // Selected payment method
  const [paymentSystems, setPaymentSystem] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false); // Submit button loading state
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Modal message
  const token = localStorage.getItem("token");
  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500", "100"]; 
  const [selectedOption, setSelectedOption] = useState(null);




  const navigate = useNavigate(); // Initialize navigate

  const handleSelection = (id) => {
    setSelectedOption(id); 
  };


  // Fetch payment methods from API
  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin, 
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Remove duplicate payment methods based on name
          const uniqueItems = [];
          const seenNames = new Set();
    
          data.forEach((item) => {
            if (!seenNames.has(item.name)) {
              seenNames.add(item.name);
              uniqueItems.push(item);
            }
          });
    
          setItems(uniqueItems); // Set the unique items
        })
        .catch((error) => console.error("Error fetching payment methods:", error));
    }, []);

  // Fetch promotions from API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/promotion`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin, // Pass the Origin header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const activePromotions = data.filter((promo) => promo.status === "active");
        setPromotions(activePromotions);
        if (activePromotions.length > 0) {
          setSelectedPromotion(activePromotions[0]);
        }
      })
      .catch((error) => console.error("Error fetching promotions:", error));
  }, []);


  useEffect(() => {
    if (paymentMethod?.name) {
      const fetchPaymentMethodDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/showpayment-menthod?name=${paymentMethod.name}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Origin': window.location.origin,
            },
          });
          const data = await response.json();

          if (data && data.success) {
            setPaymentSystem(data.data);
            console.log("Fetched Payment System:", data.data);
          }
        } catch (error) {
          console.error("Error fetching payment method details:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPaymentMethodDetails();
    }
  }, [paymentMethod, token]);

  const handleItemClick = (name) => {
    const selectedMethod = items.find((item) => item.name === name);
    setPaymentMethod(selectedMethod); 
     
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount); 
  };

  const handlePromotionChange = (event) => {
    const selectedId = event.target.value;
    const selectedPromo = promotions.find((promo) => promo.id === parseInt(selectedId));
    setSelectedPromotion(selectedPromo || null); 
  };
  const selectedPaymentSystem = paymentSystems.find(item => item.id === selectedOption);

  const handleSubmit = async () => {
    // Validate each field with specific error messages
    if (!accountNumber || accountNumber.length !== 11 || !/^\d{11}$/.test(accountNumber)) {
      setModalMessage("Please provide a valid 11-digit account number.");
      setShowModal(true);
      return;
    }
    
    if (!transactionId) {
      setModalMessage("Please provide your TRX ID. It should be valid.");
      setShowModal(true);
      return;
    }
  
    if (!activeAmount) {
      setModalMessage("Please fill in your amount.");
      setShowModal(true);
      return;
    }
  
    if (!paymentMethod) {
      setModalMessage("Please select a valid payment method.");
      setShowModal(true);
      return;
    }
    if (!selectedPaymentSystem) {
      setModalMessage("Please select a Depsoit channel");
      setShowModal(true);
      return;
    }
  
    setSubmitLoading(true);
    const remoteId = localStorage.getItem("remote_id");
  
    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      transaction_id: transactionId,
      amount: activeAmount,
      payment_id: selectedPaymentSystem.id,
      promotion_id: selectedPromotion?.id || null, 
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-deposit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage("Submission failed: " + errorData.message);
        setShowModal(true);
        return;
      }
  
      const data = await response.json();
  
      if (data) {
        setModalMessage("Submission successful!");
  
        navigate("/payment-request", {
          state: {
            amount: activeAmount,
            payment_icon: selectedPaymentSystem.icon,
          },
        });
      } else {
        setModalMessage(data.message);
        setShowModal(true); // Show modal with the error message
      }
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true); // Show modal on error
    } finally {
      setSubmitLoading(false);
    }
  };
  
  
  if(isLoading) {
    return(
        <div className="three-dots-loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
}


  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "50%" }} />
              <div className="btn active">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    {/* Promotion Selector */}
                    <div className="option-group select-bar">
                      <label>
                        <span
                          className="item-icon"
                          style={{
                            backgroundImage:
                              'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-selectpromotion.svg")',
                          }}
                        />
                        <div>Select Promotion</div>
                      </label>
                      <div className="option-wrap">
                        <select
                          onChange={handlePromotionChange}
                          defaultValue={selectedPromotion?.id || ""}
                        >
                          {promotions.map((promotion) => (
                            <option key={promotion.id} value={promotion.id}>
                              {promotion.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <DepositNotice />

                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.name)}
                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                    <div className="tag-rebate-money"><p ><span>+</span>{selectedPromotion?.deposit_bouns ? Math.round(selectedPromotion.deposit_bouns) : 0}
                                    <span>%</span></p></div>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div  className="deposit-normal ng-tns-c324864554-6 ng-star-inserted">
                        <div  className="ng-tns-c324864554-6 ng-trigger ng-trigger-staggerFadeAnimation ng-star-inserted" style={{}}>
                          <div  className="menu-box ng-tns-c324864554-6 ng-star-inserted" style={{}}>
                            <div  className="title ng-tns-c324864554-6">
                              <h2  className="ng-tns-c324864554-6"><span  className="ng-tns-c324864554-6">Deposit Channel</span></h2>
                            </div>
                            <div  className="select-group checkbox-style checkbox-height-set ng-tns-c324864554-6">
                            <ul className="col4 ng-tns-c324864554-6">
                            {paymentSystems.map(paymentSystem => (
                              <React.Fragment key={paymentSystem.id}>
                                {/* Show CashOut if account_type is Agent */}
                                {paymentSystem.account_type === "Agent" && (
                                  <li className="ng-star-inserted">
                                    <input  type="radio"  onChange={() => {
                                        handleSelection(paymentSystem.id); // Call first function
                                        setActiveItem(paymentSystem.id);   // Call second function
                                      }}
                                    name="depositSetting" id={`depositSetting_${paymentSystem.id}`} />
                                    <label htmlFor={`depositSetting_${paymentSystem.id}`}>
                                      <span>CashOut</span>
                                      <div className="tag-recommond ng-star-inserted">
                                      <span className="item-icon" style={{ maskImage: `url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-recommond.svg?v=1735038087741")` }} />

                                      </div>
                                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/select-check.svg?v=1735038087741")' }} />
                                    </label>
                                  </li>
                                )}
                                
                                {/* Show Send Money if account_type is Personal */}
                                {paymentSystem.account_type === "Personal" && (
                                  <li className="ng-star-inserted">
                                    <input type="radio" name="depositSetting" onChange={() => handleSelection(paymentSystem.id)}  id={`depositSetting_${paymentSystem.id}`} />
                                    <label htmlFor={`depositSetting_${paymentSystem.id}`}>
                                      <span>Send Money</span>
                                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/select-check.svg?v=1735038087741")' }} />
                                    </label>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 100.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="depositAmount"
                                id={`depositAmount_${amount}`}
                              />
                              <label htmlFor={`depositAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="inputbox">
                        <input
                          type="text"
                          name="amount"
                          className="input text-right"
                          value={activeAmount}
                          onChange={(e) => setActiveAmount(e.target.value)} 
                          onClick={(e) => e.stopPropagation()} 
                        />
                        </div>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="accountNumber">Account</label>
                        <div className="inputbox">
                          <input
                            type="text"
                            className="input text-right"
                            style={{ paddingRight: "60px" }}
                            value={selectedPaymentSystem?.account_number || ''}
                            readOnly
                          />
                          <button
                            onClick={() => {
                              const accountNumber = selectedPaymentSystem?.account_number;
                              if (accountNumber) {
                                navigator.clipboard.writeText(accountNumber);
                                alert(`Account number copied: ${accountNumber}`);
                              } else {
                                alert("No account number to copy!");
                              }
                            }}
                            style={{
                              position: "absolute",
                              right: "0",
                              top: "0",
                              bottom: "0",
                              background: "transparent",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            <img src="./icons/copy.svg" alt="Copy" />
                          </button>
                        </div>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="accountNumber">Sender Account</label>
                        <div className="inputbox">
                          <input
                            type="text"
                            name="accountNumber"
                            className="input text-right"
                            value={accountNumber}
                            onChange={(e) =>
                              setAccountNumber(e.target.value)
                            }
                            placeholder="Account Here"
                          />
                        </div>
                      </div>

                      <div className="input-group money">
                        <label htmlFor="transactionId">TRX ID</label>
                        <div className="inputbox">
                          <input
                            type="text"
                            name="transactionId"
                            className="input text-right"
                            value={transactionId}
                            onChange={(e) =>
                              setTransactionId(e.target.value)
                            }
                            placeholder="TRX ID Here"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div
                      style={{ textAlign: "center" }}
                      className="member-content "
                    >
                      <button
                        className="button"
                        onClick={handleSubmit}
                        disabled={submitLoading}
                      >
                        {submitLoading ? "Submitting..." : "Submit"}
                      </button>
                    </div>

                    {/* Modal for Messages */}
                   {/* Modal for Messages */}
                   <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MDeposit;
