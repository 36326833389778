import React, { useEffect, useState } from 'react';

const Deposit = () => {
  const [activeTab, setActiveTab] = useState('deposit'); // State to track the active tab
  const [items, setItems] = useState([]); // Payment methods
  const [promotions, setPromotions] = useState([]); // Promotions data
  const [selectedPromotion, setSelectedPromotion] = useState(null); // Selected promotion
  const [selectedPromotionId, setSelectedPromotionId] = useState(null); // State for selected promotion
  const [selectedPromotions, setSelectedPromotions] = useState(null); // State for selected promotion

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`)
      .then(response => response.json())
      .then(data => setItems(data))
      .catch(error => console.error('Error fetching payment methods:', error));
      
  }, []);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/promotion`)
      .then(response => response.json())
      .then(data => {
        const activePromotions = data.filter(promotion => promotion.status === 'active');
        setPromotions(activePromotions);
        // Set default to no promotion selected initially
        setSelectedPromotion(null);
        if (activePromotions.length > 0) {
          setSelectedPromotionId(activePromotions[0].id);
          setSelectedPromotion(activePromotions[0]); 
        }
      })
      .catch(error => console.error('Error fetching promotions:', error));
  }, []);

  useEffect(() => {
    // Initialize JavaScript functions for page setup if they exist globally
    if (window.FE && window.FE.DepositHandler) {
      window.FE.docReady(window.FE.DepositHandler.init);
    }
    
    if (window.FE && window.PageConfig) {
      window.PageConfig.enableRefreshPaymentStatus = true;
    }

    const updateVerifyLink = (verifyLinkTag, verified, verifiedType) => {
      if (!verified) {
        verifyLinkTag.classList.add("status-verify-no");
        verifyLinkTag.onclick = () => window.FE.DialogHandler.showContactVerification(verifiedType);
      } else {
        verifyLinkTag.classList.remove("status-verify-no");
        verifyLinkTag.classList.add("status-verify-ok");
      }
    };

    const verifyMail = document.querySelector("#verify-mail-link");
    const verifyPhone = document.querySelector("#verify-phone-link");

    if (window.FE && window.FE.PageConfig && window.ContactVerifiedType) {
      updateVerifyLink(verifyMail, window.FE.PageConfig.emailVerified, window.ContactVerifiedType.Email.value);
      updateVerifyLink(verifyPhone, window.FE.PageConfig.phoneVerified, window.ContactVerifiedType.Phone.value);

      let contactVerifyCount = 0;
      if (window.FE.PageConfig.emailVerified) contactVerifyCount++;
      if (window.FE.PageConfig.phoneVerified) contactVerifyCount++;

      const statusElement = document.getElementById("contactVerifyStatus");
      if (contactVerifyCount === 1) {
        statusElement.textContent = window.FE.Translate.get("fe.text.normal");
        statusElement.parentNode.classList.add("normal-state");
        statusElement.parentNode.classList.remove("danger-state");
      } else if (contactVerifyCount === 2) {
        statusElement.textContent = window.FE.Translate.get("fe.text.safe");
        statusElement.parentNode.classList.add("safe-state");
        statusElement.parentNode.classList.remove("danger-state");
      }

      if (window.FE.PageConfig.isPromotionWallet) {
        document.getElementById("topOverviewTotalBonusWallet").parentNode.style.display = "block";
      }
    }

    if (window.FE && window.FE.PageInitHandler) {
      window.FE.PageInitHandler.initBalance(false);
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="main-content-wrap">
      <div className="tips-info verify-tips" style={{ display: 'none' }}>
        <div className="tips-title-box">
          <h6>
            <p>Please complete the verification below before you proceed with the withdrawal request.</p>
          </h6>
          <div className="close-tips"></div>
        </div>
        <ol>
          <li className="info-personal">
            <label>Personal Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Birthday</li>
              </div>
            </ul>
          </li>
          <li className="info-contact">
            <label>Contact Info</label>
            <ul>
              <div data-pagetype="6">
                <li>Phone Number</li>
              </div>
            </ul>
          </li>
        </ol>
      </div>

      <div className="top-overview-wrap">
        <ul>
          <li>
            <div className="left-overview-area">
              <label>Main Wallet</label>
              <div className="total-money totalBalanceWallet">
                <span className="balance" style={{ paddingRight: '7px' }}>৳</span>
                <p className="balance">0</p>
              </div>
            </div>
          </li>
          <li style={{ display: 'none' }}>
            <div id="topOverviewTotalBonusWallet" className="left-overview-area">
              <label>Bonus Wallet</label>
              <div className="total-money totalBonusWallet">
                <span className="balance" style={{ paddingRight: '7px' }}>৳</span>
                <p>0</p>
              </div>
            </div>
          </li>
          <li>
            <div className="left-overview-area danger-state">
              <label>Security Level</label>
              <p id="contactVerifyStatus">Unverified</p>
            </div>
            <div className="right-overview-area">
              <a id="verify-mail-link" className="verify-mail status-verify-no"></a>
              <a id="verify-phone-link" className="verify-phone status-verify-no"></a>
            </div>
          </li>
        </ul>
      </div>

      <div id="mainContentBody" className="replaceable-block">
        <style>
          {`
            .submit-money-group input.error { margin-left: 0px; }
            #content_bankType li.active { display: block; }
            #content_bankType li { display: none; }
          `}
        </style>

        <main className="container-player-wrapper">
          <form id="depositForm" noValidate data-gtm-form-interact-id="5">
            <div className="container-player-group">
              <div className="player-title-box">
                <h2>Funds</h2>
                <div className="tab-primary-box">
                  <ul>
                    <li onClick={() => handleTabChange('deposit')} className={activeTab === 'deposit' ? 'active' : ''}>
                      <a data-toggle="tab" data-pagetype="1">Deposit</a>
                    </li>
                    <li onClick={() => handleTabChange('withdrawal')} className={activeTab === 'withdrawal' ? 'active' : ''}>
                      <a data-toggle="tab" data-pagetype="2">Withdrawal</a>
                    </li>
                  </ul>
                  <div className="slider-tab" style={{ width: "160px", left: activeTab === 'deposit' ? "0px" : "160px" }}></div>

                </div>
              </div>

              <div id="content_deposit" className="main-tab-content">
                <section className={`tab-pane deposit ${activeTab === 'deposit' ? 'active' : 'd-none'}`}>
                {promotions.map(promotion => (
                  <section className="deposit-discount-group">
                    <div className="title-box">
                      <h3>Promotions</h3>
                      <div className="deposit-discount-select">Promotion&nbsp;<span>9</span></div>
                    </div>
                    
                    <div id="selectedBonusTemplateContainer" className="coupon-group">
                      <div className="coupon-banner bg-load pre-load data-image" 
                           style={{ backgroundImage: "url('https://img.j189eb.com/upload/promotion/image_31021.jpg')" }}>
                      </div>
                      <div className="coupon-cont">
                        <label>Promotion</label>
                        <div className="coupon-tit"><strong>{promotion.name}</strong></div>
                        <p>ডেইলি গিফট</p>
                        <time>2024/09/01 00:00:00 to 2025/12/31 23:59:59 end</time>
                        <button className="promotion-detail-btn" data-messagetype="3" data-id="16875" onClick={(e) => e.preventDefault()}>
                          Detail
                        </button>
                      </div>
                    </div>
                  </section>
                   ))}

                  <section>
                    <div className="title-box">
                      <h3>Payment Method</h3>
                    </div>
                    <ul id="content_bankType" className="choose-box col-5">
                    {items.map((item) => (
              item.status === 'active' && (
                      <li data-maintain="false" className="active">
                        <input type="radio" name="bankType" id="bankType_bKash" data-banktype="2048" />
                        <label htmlFor="bankType_bKash">
                          <div className="pre-load">
                            <img className="img-load" 
                             src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                             alt={item.name}
                            />
                          </div>
                          <p>{item.name}</p>
                          <div className="tag-rebate-money"><p><span>+</span>2 <span>%</span></p></div>
                        </label>
                      </li>
                    ) ))}
                    
                    </ul>
                  </section>

                  <section id="paymentTypeSection">
                    <div className="title-box">
                      <h3>Payment Type</h3>
                    </div>
                    <div className="tab-module tab-module-normal active">
                      <div id="content_paymentType" className="tab-secondary-box">
                        <ul className="tabs">
                          <li className="state-repair active">
                            <input type="radio" name="paymentType" id="paymentType_44" style={{ display: 'none' }} />
                            <label htmlFor="paymentType_44">
                              <p>bKash Payment</p>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-container">
                        <ul className="tab-content">
                          <li className="active">
                            <section id="content_depositChannel">
                              <div className="title-box">
                                <h4>Deposit Channel</h4>
                              </div>
                              <div className="deposit-pay-box">
                                <ul className="choose-box col-5">
                                  <li data-maintain="false">
                                    <input
                                      type="radio"
                                      name="depositSetting"
                                      id="depositSetting_257"
                                      data-accountid="257"
                                      data-min="500"
                                      data-max="25000"
                                      data-maintain="false"
                                    />
                                    <label htmlFor="depositSetting_257">
                                      <p>CashOut</p>
                                    </label>
                                    <div className="tag-recommond"></div>
                                  </li>
                                  <li data-maintain="false">
                                    <input
                                      type="radio"
                                      name="depositSetting"
                                      id="depositSetting_1728"
                                      data-accountid="1728"
                                      data-min="500"
                                      data-max="10000"
                                      data-maintain="false"
                                    />
                                    <label htmlFor="depositSetting_1728">
                                      <p>Send Money</p>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </section>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>

                  {/* Deposit Amount Section */}
                  <section className="save-money-box" id="content_fiatCurrencyAmountBox">
                    <div className="deposit-save-money">
                      <div className="title-box">
                        <h4>Amount</h4>
                        <span id="tipsMinMax">৳ 500 - ৳ 25,000</span>
                      </div>
                      <div id="content_inputAmount" className="add-money-box active">
                        <ul className="choose-box col-5">
                          <li className="style-add-amount">
                            <p data-amount="2000"><span>+</span>2,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="3000"><span>+</span>3,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="5000"><span>+</span>5,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="10000"><span>+</span>10,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="15000"><span>+</span>15,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="20000"><span>+</span>20,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="25000"><span>+</span>25,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="1000"><span>+</span>1,000</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="500"><span>+</span>500</p>
                          </li>
                          <li className="style-add-amount">
                            <p data-amount="100"><span>+</span>100</p>
                          </li>
                        </ul>
                      </div>
                      <div className="tips-group" style={{ display: 'block' }}>
                        <p>Dear all member, to speed up your deposit process, kindly follow these steps :</p>
                        <p>1. Verify the phone number you used for deposit.</p>
                        <p>2. Input the correct reference number.</p>
                        <p>3. Process only selected amount.</p>
                        <p>4. Attach the successful slip.</p>
                        <p>Reminder:</p>
                        <p>Do not save the phone number displayed on Jeetbuzz website on your device to avoid losing money.</p>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-box-50" id="upiAccountDiv" style={{ display: 'none' }}>
                        <div className="title-box">
                          <h4>Your UPI ID</h4>
                        </div>
                        <input type="text" name="upiAccNo" id="upiAccNo" placeholder="UPI ID" />
                        <span className="error" style={{ display: 'none' }}>error words</span>
                      </div>
                      <div className="input-box-50 submit-money-group">
                        <div className="input-box input-money">
                          <span className="currency">৳</span>
                          <input
                            id="depositAmount"
                            name="depositAmount"
                            type="number"
                            style={{ marginTop: 0 }}
                            onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()} // Prevent 'e' for exponential
                            aria-required="true"
                            placeholder="0.00"
                            readOnly
                          />
                          <input className="reset-btn" type="reset" style={{ top: '1.25rem' }} />
                        </div>
                        <button className="btn-default button submitPayment disable" disabled>
                          <span className="submit">Deposit</span>
                          <span className="loading">
                            <div className="lds-rolling">
                              <div className="animation"></div>
                            </div>
                          </span>
                          <span className="check">
                            <span className="icon"></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </section>
                </section>

                {/* Withdrawal Tab Section */}
                <section className={`tab-pane withdrawal ${activeTab === 'withdrawal' ? 'active' : 'd-none'}`}>
                  <h3>Withdrawal Section</h3>
                  <p>This is where the withdrawal functionality will be implemented.</p>
                  {/* Add the withdrawal logic and structure here as needed */}
                </section>
              </div>
            </div>
          </form>
        </main>

        <div className="guild-list-wrapper">
          <aside className="record-aside-box record-box">
            <div className="list-aside deposit-aside active">
              <h4>Deposit Records</h4>
              <div className="player-guild-cont" style={{ display: 'none' }}>
                <ul id="depositRecordContainer" className="player-list"></ul>
              </div>
              <a className="link-more" data-pagetype="5">More</a>
              <div className="empty-cont" style={{}}>
                <img src="https://www.jeetbuzz.com/images/web/player/table/no-value.svg" />
                <p>No Data</p>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
