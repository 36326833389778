import React, { useState } from "react";
import Logo from "../common/mobile/Logo";


const Footer = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const origin = window.location.origin.replace(/^https?:\/\/(www\.)?/, '').replace(/(\.com|\.live|\.net)$/i, '');



  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="footer-wrap">
      <div className="footer-1200">
        <div className="description">
        <div
             className={`description-section ${isExpanded ? '' : 'fold'}`}
            
            style={{
              height: isExpanded ? "auto" : "970px"
            }}
          >
            <div className="description-container" id="footerSeoContent">
              <div className="title">
                <span className="title-text">
                  {origin} Bangladesh - Your Ultimate Destination for Online Gaming and Betting
                </span>
              </div>
              <div className="main-landing-page-content">
                <span>
                  <p>
                    Welcome to {origin} Bangladesh, where the thrill of online gaming meets the excitement of sports betting, all under one roof. Whether you’re a seasoned gamer or new to the world of online gambling,
                    {origin} offers a dynamic platform that caters to all your gaming and betting needs. With a wide array of games from top providers and an innovative betting exchange, we are here to deliver an
                    unparalleled experience to players across Bangladesh.
                  </p>
                  <p>
                    <strong>A World of Gaming Options</strong><br />
                    At {origin}, we pride ourselves on offering a diverse selection of games that appeal to every type of player. Our platform features an extensive lineup of games from industry-leading providers such as
                    I-Sports, JDB, CQ9, Pocket Games, and Evolution. Whether you’re into high-octane slots, classic table games, or immersive live dealer experiences, our collection has something for everyone.
                  </p>
                  <p>
                    For fans of live casino action, Sexy and Evolution bring the glamour of the casino floor directly to your screen with professional dealers and real-time gameplay. If you prefer the strategic challenge
                    of poker, BPOKER offers a variety of tables and tournaments to test your skills against players from around the world.
                  </p>
                  <p>
                    <strong>Explore Innovative Betting Exchanges</strong><br />
                    {origin} is not just about casino games; we also offer an exciting betting exchange that allows you to bet directly against other players. This unique feature gives you more control over your bets and
                    often better odds compared to traditional betting platforms. Whether you’re placing a wager on a cricket match, football game, or horse race, our Exchange platform provides you with the tools to make
                    smart, informed bets.
                  </p>
                  <p>
                    Our sports betting options are powered by top providers like JB Sports, Horsebook, and E1SPORT, ensuring that you have access to the latest odds and a wide range of betting markets. From pre-match
                    bets to live, in-play betting, we cover all the major sports and events, giving you the chance to bet on your favorite teams and athletes.
                  </p>
                  <p>
                    <strong>Dive into a Diverse Game Collection</strong><br />
                    {origin} Bangladesh is home to a vast array of games that cater to every player’s preferences. Our partnership with providers such as Joker, KA, RT, SG, and JILI means that you can explore a rich
                    variety of slots, from high-volatility games with massive jackpots to more casual, low-risk options. Each game is designed with cutting-edge graphics, innovative features, and engaging themes to keep
                    you entertained.
                  </p>
                  <p>
                    For players looking for something a bit different, we offer a selection of niche games from providers like SPRIBE, Yellow Bat, HotRoad, and FASTSPIN. These games offer unique gameplay mechanics and
                    fresh, exciting content that stands out from the usual casino offerings.
                  </p>
                  <p>
                    <strong>Maximize Your Winnings with Bonuses and Promotions</strong><br />
                    At {origin}, we believe in rewarding our players. That’s why we offer a variety of bonuses and promotions designed to enhance your gaming experience. New players can take advantage of our generous
                    welcome bonus, while regular players can enjoy ongoing promotions such as deposit matches, free spins, and cashback offers. These bonuses give you extra value and more chances to win big.
                  </p>
                  <p>
                    Our referral program is another way to boost your rewards. Invite your friends to join {origin}, and for every friend who signs up and makes a deposit, you’ll earn bonus credits that can be used to
                    explore our vast game library. This not only increases your potential winnings but also allows you to share the excitement of {origin} with others.
                  </p>
                  <p>
                    <strong>Play with Confidence</strong><br />
                    Your safety and security are our top priorities at {origin}. We’ve partnered with reputable providers like PT, WorldMatch, NETENT, and PNG to ensure that all our games are fair and that your personal
                    information is protected. Our platform uses state-of-the-art encryption technology to safeguard your data, so you can play with peace of mind knowing that you’re in a secure environment.
                  </p>
                  <p>
                    <strong>Join the {origin} Bangladesh Community</strong><br />
                    Are you ready to experience the best in online gaming and betting? Join the {origin} Bangladesh community today and discover a world of entertainment at your fingertips. Our user-friendly platform is
                    designed to make it easy for you to navigate through our extensive game library, place bets on your favorite sports, and take advantage of our exciting promotions. Don’t forget to invite your friends
                    to join the fun and start earning extra rewards through our referral program. With {origin}, the possibilities are endless – start your gaming adventure now and see where the excitement takes you!
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div
            className="show-more-btn btn-default"
            id="footerSeoToggleBtn"
            onClick={toggleExpand} // Toggle expand on click
          >
            {isExpanded ? "Show Less" : "Show More"} {/* Button text changes based on state */}
          </div>
        </div>

        <ul className="footer-link-group">
          {/* Footer Links */}
          <li>
            <div className="icon-wrap icon1"></div>
            <div className="text-wrap">
              <h4>Customer Support</h4>
              <h5>Available 24/7 to assist you</h5>
            </div>
          </li>
          <li>
            <div className="icon-wrap icon2"></div>
            <div className="text-wrap">
              <h4>New Member Guide</h4>
              <h5>Check out FAQ and guides</h5>
              <a href="https://{origin}help.com/">Explore Now</a>
            </div>
          </li>
          <li>
            <div className="icon-wrap icon3"></div>
            <div className="text-wrap">
              <h4>Brand Ambassador</h4>
              <h5>Play with celebrity</h5>
              <a href="#" data-pagetype="43">Have Fun Now</a>
            </div>
          </li>
          <li className="android-img">
            <a href="/page/site/component/appDownload.jsp" target="_blank">
              <img src="https://www.{origin}.com/images/web/footer/android-en.png" alt="Download for Android" />
            </a>
          </li>
        </ul>

        <ul className="footer-partner-wrap">
          {/* Responsible Gaming */}
          <li className="duty">
            <h5>Responsible Gaming</h5>
            <a href="https://www.gamcare.org.uk/" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/safe1.png" alt="GamCare" />
            </a>
            <a href="https://{origin}help.com/account/18-policy/" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/safe2.png" alt="18+ Policy" />
            </a>
            <a href="https://{origin}help.com/responsible-gaming/" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/safe3.png" alt="Responsible Gaming" />
            </a>
          </li>
          
          {/* Payment Methods */}
          <li className="payment">
            <h5>Payment Methods</h5>
            {/* Add payment icons here */}
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay16.png" alt="Bank Deposit" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay22.png" alt="bKash" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay33.png" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay34.png" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay45.png" alt="UPAY" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay59.png" alt="OKWallet" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay60.png" alt="SureCash" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay61.png" alt="tapPay" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay47.png" alt="USDT" /></a>
            <a href="#"><img src="https://www.{origin}.com/images/web/footer/color-white/pay91.png" alt="BTC" /></a>
          </li>

          {/* Social Media */}
          <li className="social-media">
            <h5>Community Websites</h5>
            <a href="https://cutt.ly/X8zvJCK" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/FaceBook.png" alt="Facebook" />
            </a>
            <a href="https://cutt.ly/F8zWryo" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/Instagram.png" alt="Instagram" />
            </a>
            <a href="https://cutt.ly/V8zQ3in" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/Twitter.png" alt="Twitter" />
            </a>
            <a href="https://cutt.ly/n8zWnUX" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/Pinterest.png" alt="Pinterest" />
            </a>
            <a href="https://cutt.ly/A8zWKKF" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/Youtube.png" alt="YouTube" />
            </a>
            <a href="https://cutt.ly/Dwkk45sZ" target="_blank" rel="noopener noreferrer">
              <img src="https://www.{origin}.com/images/web/footer/color-white/Telegram.png" alt="Telegram" />
            </a>
          </li>

          {/* Sponsorship and Ambassadors */}
          <li className="ft-spon">
            <h5>Sponsorships</h5>
            <div className="ft-spon__icon">
              <img className="spon-icon" src="https://www.{origin}.com/images/web/sponsor/delhi-bulls.png" alt="Delhi Bulls Sponsor" />
              <p>Delhi Bulls<br />2023 - 2024<span>Title Sponsor</span></p>
            </div>
          </li>
          
          <li className="ft-ambassador">
            <h5>Brand Ambassadors</h5>
            <ul className="header-btn-wrap">
              <li>
                <img src="https://www.{origin}.com/images/v1/web/jb/footer/ambassador_signature/andre-russell.png" alt="Andre Russell" />
                <a href="#" data-pagetype="43" data-active="andre-russell">
                  Andre Dwayne Russell<br />2024 - 2026
                </a>
              </li>
              <li>
                <img src="https://www.{origin}.com/images/v1/web/jb/footer/ambassador_signature/mcgrath.png" alt="Glenn McGrath" />
                <a href="#" data-pagetype="43" data-active="glenn-mcgrath">
                  Glenn McGrath<br />2023 - 2024
                </a>
              </li>
            </ul>
          </li>

          {/* Gaming License */}
          <li className="gaming-license">
            <h5>Gaming License</h5>
            <img src="https://www.{origin}.com/images/web/footer/gaming_license.png" alt="Gaming License" />
          </li>
        </ul>

        {/* Footer License and Logo */}
        <div className="footer-license">
          <div className="license-cont"></div>
        </div>
        
        <div className="footer-logo-wrap">
          <div className="footer-logo">
            <Logo/>
          </div>
          <div className="link-wrap">
            <ul>
              <li><a href="/" target="_blank" rel="noopener noreferrer">Affiliate</a></li>
              <li><a href="#" data-pagetype="25">Responsible Gaming</a></li>
              <li><a href="#" data-pagetype="21">About Us</a></li>
            </ul>
            <p>© 2024 {origin} Copyrights. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;