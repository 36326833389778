import React, { useState, useEffect } from "react";

const MobileCarouselBanner = () => {
    const [sliders, setSliders] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchSliders = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
                const data = await response.json();
                const activeMainSliders = data.filter(slider => slider.status === 'active' && slider.slider_type === 'Main');
                setSliders(activeMainSliders);
            } catch (error) {
                console.error('Error fetching sliders:', error);
            }
        };

        fetchSliders();
    }, []);

    useEffect(() => {
        if (sliders.length > 0) {
            // Interval for updating the progress bar
            const progressInterval = setInterval(() => {
                setProgress((prev) => (prev < 100 ? prev + 1 : 0));
            }, 30);

            // Interval for changing the slide
            const slideInterval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % sliders.length);
                setProgress(0); // Reset progress each time the slide changes
            }, 3000);

            // Clear intervals on component unmount
            return () => {
                clearInterval(progressInterval);
                clearInterval(slideInterval);
            };
        }
    }, [sliders.length]); // Run effect only when sliders are fetched

    const goToSlide = (index) => {
        setCurrentIndex(index);
        setProgress(0);
    };

    return (
        <mcd-carousel-banner id="mcd-carousel-banner-1">
            <div dir="ltr" className="banner">
                <div className="banner-v1">
                    <div className="carousel-wrap style-init siblings" data-auto="true" data-delay={500}>
                        <div
                            className="cdk-drag item-drag"
                            style={{ transform: `translate3d(${currentIndex * -70 + 70}%, 0px, 0px)` }}
                        >
                            <div className="item-left">
                                <div className="item-wrap">
                                    {sliders.map((slider, index) => (
                                        <div
                                            key={slider.message_id}
                                            className="item"
                                            message-id={slider.message_id}
                                            style={{
                                                width: '70%',
                                            }}
                                            onClick={() => goToSlide(index)}
                                        >
                                            <div
                                                className="item-pic"
                                                style={{
                                                    backgroundImage: `url("${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}")`
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <ul className="dot-group style-bar">
                            {sliders.map((_, index) => (
                                <li
                                    key={index}
                                    onClick={() => goToSlide(index)}
                                    className={`ng-star-inserted ${index === currentIndex ? 'active' : ''}`}
                                >
                                    <span className="dot-progress" style={{ animationDuration: '3000ms' }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </mcd-carousel-banner>
    );
};

export default MobileCarouselBanner;
