import React, { useState, useEffect } from 'react';

const FeatureGames = () => {
    const [featuredGames, setFeaturedGames] = useState([]);

    // Fetch games data
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
                const data = await response.json();
                const featureGames = data.filter(game => game.status === 'active' && game.slider_type === 'Features');
                setFeaturedGames(featureGames); // Set the fetched games data
            } catch (error) {
                console.error('Error fetching games:', error);
            }
        };

        fetchGames();
    }, []);

    return (
        <mcd-feature-games>
            <div className="recommend feature-games">
                <div className="recommend-title">
                    <h2>Featured Games</h2>
                </div>
                <div className="recommend-bg games">
                    <div className="recommend-main games-main">
                        {featuredGames.length > 0 ? (
                            featuredGames.map((game, index) => (
                                <div className="games-box" key={index}>
                                    <div className="pic">
                                        <a href={game.link ? game.link : '#'}>
                                            <img 
                                                alt={game.title} 
                                                src={`${process.env.REACT_APP_API_URL}/storage/${game.thubmnail}`} 
                                                loading="lazy" 
                                            />
                                        </a>
                                    </div>
                                    <div className="text">
                                        <h3>{game.title}</h3>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </div>
        </mcd-feature-games>
    );
};

export default FeatureGames;