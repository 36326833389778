import React, { useState, useEffect } from 'react';

const ScrollBanner = () => {
  const [sliders, setSliders] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // Fetch sliders data
  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
        const data = await response.json();
        const activeMainSliders = data.filter(slider => slider.status === 'active' && slider.slider_type === 'Favourite');
        setSliders(activeMainSliders); // Set the fetched sliders
      } catch (error) {
        console.error('Error fetching sliders:', error);
      }
    };

    fetchSliders();
  }, []);

  return (
    <mcd-scroll-banner>
      <div className="recommend scroll-banner">
        <div className="recommend-title">
          <h2>Favourites</h2>
        </div>
        <div className="recommend-bg">
          <div className="recommend-main">
            {sliders.length > 0 ? (
              sliders.map((slider, index) => (
                <div className="recommend-card" key={index}>
                  <a href={slider.link ? slider.link : '#'}>
                    <img 
                      alt={`slider_image_${index}`} 
                      src={`${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}`} 
                      loading="lazy" 
                    />
                  </a>
                </div>
              ))
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </div>
    </mcd-scroll-banner>
  );
};

export default ScrollBanner;