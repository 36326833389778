import React, { useState, useEffect } from "react";

function Logo() {
    const [logo, setLogo] = useState([]);
    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
                if (!response.ok) {
                    throw new Error("Failed to fetch promotions");
                }
                const data = await response.json();
                setLogo(data[0].logo);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            } 
        };
    
        fetchPromotions();
    }, []);

  return (
    <div className="logo" tabIndex="0" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/storage/${logo}')` }}></div>
  );
}
export default Logo;