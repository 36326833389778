import React, { useState, useEffect } from "react";
import UniversalBack from "../../common/mobile/UniversalBack";

function ForgotPassword(){
     const [activeTab, setActiveTab] = useState("email");

     const [logo, setLogo] = useState([]);
         useEffect(() => {
             const fetchPromotions = async () => {
                 try {
                     const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
                     if (!response.ok) {
                         throw new Error("Failed to fetch promotions");
                     }
                     const data = await response.json();
                    
         
                    
                     setLogo(data[0].logo);
                 } catch (error) {
                     console.error("Error fetching promotions:", error);
                 } 
             };
         
             fetchPromotions();
         }, []);
    return(
        
        <div  className="wrap ">
            <header  id="header" mcdneuaexpose data-neua="{&quot;event_name&quot;:&quot;view&quot;,&quot;event_type&quot;:&quot;exposure&quot;,&quot;event_page&quot;:&quot;Online Cricket Betting in Bangladesh | JeetBuzz&quot;,&quot;event_payload&quot;:{&quot;loc&quot;:&quot;header&quot;,&quot;version&quot;:&quot;v1&quot;}}" className="ng-star-inserted register">
  <div  className="header-left-btn-group">
    <UniversalBack/>
    
    <div  className="menu-btn">
      <ul >
        <li  />
        <li  />
        <li  />
      </ul>
    </div>
  </div>
  <div  className="header-title ng-star-inserted">Forgot password?</div>
  
</header>

  <div  className="content  fixed-tab register-content" style={{}}>
    <div  className=" ng-trigger ng-trigger-routeLayoutPageAni">
        <div  className="tab-btn-section ">
            <div className="tab-btn tab-btn-page ng-star-inserted">
            <div className="line" style={{ width: 'calc(50%)', transform: activeTab === "email" ? 'translate(0, 0)' : 'translate(100%, 0)' }} />
              <div className={`btn ${activeTab === "email" ? "active" : ""}`} onClick={() => setActiveTab("email")}>
                <div  className="text">
                  Email
                
                </div>
              </div>
              <div   className={`btn ${activeTab === "sms" ? "active" : ""}`} onClick={() => setActiveTab("sms")} >
                <div  className="text">
                  SMS
                
                </div>
              </div>
             
            </div>
          
        </div>
        <div  className=" ng-trigger ng-trigger-tabPageTriggerAni">
            <div  className="tab-content tab-content-page forgetpassword ng-star-inserted">
            <div className="logo-box ng-star-inserted" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/storage/${logo}')` }} />
              {activeTab === "email" ? (
              <form  noValidate className="inner-box ng-untouched ng-pristine ng-invalid">
                <div  className="member-box radius">
                  <div  className="inputbox-wrapper">
                    <div  className="inputbox">
                      <label >Username</label>
                      <input  removewhitespace type="text" className="input ng-untouched ng-pristine ng-invalid" placeholder="Username" />
                      <input  className="clear" />
                    </div>
                    <div  style={{display: 'grid', lineHeight: '20px'}} className="ng-star-inserted">{/**/}</div>
                   
                  </div>
                  <div  className="inputbox-wrapper">
                    <div  className="inputbox">
                      <label >E-mail</label><input  type="text" className="input ng-untouched ng-pristine ng-invalid" placeholder="Input valid email" />
                      <input  className="clear" />
                    </div>
                    <div  style={{display: 'grid', lineHeight: '20px'}} className="ng-star-inserted">{/**/}</div>
                   
                  </div>
                
                </div>
               
                <div  className="button"><a >Confirm</a></div>
              </form>
               ) : (
                <form  noValidate className="inner-box ng-untouched ng-pristine ng-invalid" style={{height: 'auto'}}>
                <div  className="member-box radius">
                  <div  className="inputbox-wrapper">
                    <div  className="inputbox inputbox error">
                      <label >Username</label><input  removewhitespace type="text" className="input ng-untouched ng-pristine ng-invalid" placeholder="Username" />
                      <input  className="clear" />
                    </div>
                    <div  style={{display: 'grid', lineHeight: '20px'}} className="ng-star-inserted">{/**/}</div>
                    
                  </div>
                  <div  className="inputbox-wrapper">
                    <div  className="inputbox phone-number">
                      <label >Phone Number</label>
                      <div  className="input-wrap phone-wrap">
                        <div  className="phone-area-code">
                          <div  className="lang-select">
                            <button  value className="btn-select">
                              <li >
                                <img  value="BD" alt="BD" src="https://img.j189eb.com/jb/h5/assets/images/flag/BD.png?v=1734504295963&source=mcdsrc" loading="lazy" />
                                <span >+880</span>
                              </li>
                            </button>
                            <div  className="phone-code-list-group" style={{display: 'none'}}>
                              <ul  className="phone-code-list">
                                <li  className="ng-star-inserted">
                                  <img  value="BD" alt="BD" src="https://img.j189eb.com/jb/h5/assets/images/flag/BD.png?v=1734504295963&source=mcdsrc" loading="lazy" />
                                  <span >+880</span>
                                </li>
                                <li  className="ng-star-inserted">
                                  <img  value="IN" alt="IN" src="https://img.j189eb.com/jb/h5/assets/images/flag/IN.png?v=1734504295963&source=mcdsrc" loading="lazy" />
                                  <span >+91</span>
                                </li>
                                <li  className="ng-star-inserted">
                                  <img  value="PK" alt="PK" src="https://img.j189eb.com/jb/h5/assets/images/flag/PK.png?v=1734504295963&source=mcdsrc" loading="lazy" />
                                  <span >+92</span>
                                </li>
                               
                              </ul>
                            </div>
                          </div>
                        </div>
                        <input  type="number" name="phone" className="input ng-untouched ng-pristine ng-invalid" placeholder="Phone" /><input  className="clear" />
                      </div>
                    </div>
                    <div  style={{display: 'grid', lineHeight: '20px'}} className="ng-star-inserted">{/**/}</div>
                   
                  </div>
                  <div  className="inputbox-wrapper ng-star-inserted" style={{}}>
                    <div  className="inputbox turnstile">
                     
                        <div id="turnstile-container">
                          <div><input type="hidden" name="cf-turnstile-response" id="cf-chl-widget-7b6no_response" /></div>
                        </div>
                      
                    </div>
                  </div>
                  
                </div>
                
                <div  className="button"><a >Confirm</a></div>
              </form>
              
            )}
            </div>
          
        </div>
     
     
    </div>
  </div>

</div>

    );

}
export default ForgotPassword;