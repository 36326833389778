import { faWarning } from "@fortawesome/free-solid-svg-icons";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

function ChatTongole() {
  const [logo, setLogo] = useState([]);
  useEffect(() => {
     const fetchPromotions = async () => {
       try {
         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
         if (!response.ok) {
           throw new Error("Failed to fetch promotions");
         }
         const data = await response.json();
         setLogo(`${process.env.REACT_APP_API_URL}/storage/${data[0].mobile_logo}`);
       } catch (error) {
         console.error("Error fetching promotions:", error);
       }
     };
 
     fetchPromotions();
   }, []);
  const chatRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 70,
    y: window.innerHeight - 150,
  });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragDistance, setDragDistance] = useState(0);

  const handleStart = (e) => {
    setDragging(true);
    setDragDistance(0); // Reset drag distance at the start

    const event = e.type === "touchstart" ? e.touches[0] : e;
    const rect = chatRef.current.getBoundingClientRect();

    setOffset({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });

    // Add global event listeners
    if (e.type === "mousedown") {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
    } else if (e.type === "touchstart") {
      document.addEventListener("touchmove", handleMove, { passive: false });
      document.addEventListener("touchend", handleEnd);
    }
  };

  const handleMove = (e) => {
    if (!dragging) return;

    const event = e.type === "touchmove" ? e.touches[0] : e;
    e.preventDefault(); // Prevent scrolling on touch devices

    const newX = event.clientX - offset.x;
    const newY = event.clientY - offset.y;

    // Update the drag distance
    const distance = Math.sqrt(
      Math.pow(newX - position.x, 2) + Math.pow(newY - position.y, 2)
    );
    setDragDistance(distance);

    setPosition({ x: newX, y: newY });
  };

  const handleEnd = (e) => {
    // Prevent link click if dragging occurred
    if (dragDistance > 10) {
      e.preventDefault();
    }

    setDragging(true);

    // Remove global event listeners
    document.removeEventListener("mousemove", handleMove);
    document.removeEventListener("mouseup", handleEnd);
    document.removeEventListener("touchmove", handleMove);
    document.removeEventListener("touchend", handleEnd);
  };

    const [sociallink, setSociallink] = useState([]);
    const [error, setError] = useState(null);
   
    
  
    const fetchSocial = async () => {
            
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sociallink`);
                const data = await response.json();
                if (response.ok) {
                    setSociallink(data);
                   
                } else {
                    setError(data);
                    
                }
            } catch (error) {
                console.error('Error fetching social links:', error);
                setError('An unexpected error occurred while fetching social links');
            }
        };
        useEffect(() => {
            fetchSocial();
        }, []); 
    

  return (

    <a href={sociallink[0]?.whatsapp ? `${sociallink[0].whatsapp}` : '#'}>
      <div
        ref={chatRef}
        className="chat-tongole"
        style={{
          position: "fixed",
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: dragging ? "grabbing" : "grab",
          zIndex: 1000,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        <img
          src={logo}
          alt="Chat Icon"
          style={{ width: "26px" }}
        />
      </div>
    </a>
  );
}

export default ChatTongole;
