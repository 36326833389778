import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Toolbar from "../../navigation/mobile/Toolbar";
import Footer from "../../layout/mobile/Footer";
import MobileHeader from "../../layout/mobile/MobileHeader";
import GuestToolbar from "../../layout/mobile/GuestToolbar";
import NoData from "../../common/mobile/NoData";


function Games() {
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [providers, setProviders] = useState([]);
    const [systems, setSystems] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 
    
    const navigate = useNavigate();
    const location = useLocation();
    
    
    
    const [type, setType] = useState(location.state?.type);
    const [system, setSystem] = useState(location.state?.system);
    const [title, setTitle] = useState(location.state?.title);  // For title

   
    const [activeProvider, setActiveProvider] = useState(system ? system.split(',') : []);



    useEffect(() => {
        if (type && system) {
            fetchGames(system);
            fetchSystems(type);
        } else {
            console.error("Type or system is missing", { type, system });
        }

        const isMobileDevice = window.innerWidth <= 768;
        setIsMobile(isMobileDevice);
    }, [type, system]);


    const filtterOpen = () => {
        const chatElement = document.querySelector('.searchpage');
            chatElement.classList.add('active');
        const subEmlemt = document.querySelector('.searchpage-bar');
            subEmlemt.classList.add('active');
      };
      const filtterClose = () => {
        const chatElement = document.querySelector('.searchpage');
            chatElement.classList.remove('active');
            const subEmlemt = document.querySelector('.searchpage-bar');
            subEmlemt.classList.remove('active');
      };
      const fetchGames = (currentSystem) => {
        const url = `${process.env.REACT_APP_API_URL}/api/get-games?type=${type}&system=${currentSystem}`;

            // Log the URL to see it in the console
            console.log('Fetching data from:', url);

            fetch(url)
              .then(response => response.json())
              .then(data => {
                setGames(data.games);
                setFilteredGames(data.games);
                console.log(data);
              })
              .catch(error => {
                console.error('Error fetching games:', error);
                setError('Failed to fetch games');
              });

    };

    const fetchSystems = async (type) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }finally {
            setIsLoading(false);
        }
    };

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = games.filter(game => game.name.toLowerCase().includes(query));
        setFilteredGames(filtered);
    };

    const handleGameClick = async (gameCode, gamePlatform, gameType) => {
      if(!isLoggedIn) {
        navigate('/login');
      }
      setIsLoading(true);

        try {
            const token = localStorage.getItem('token');
            const remote_id = localStorage.getItem('remote_id');
            if (!token) {
                setError('User not logged in');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/game-run`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                   userId : remote_id,
                   externalURL: 'https://worldbaji.live',
                   isMobileLogin: isMobile,
                   gameCode:gameCode,
                   gameType: gameType,
                   platform: gamePlatform
                   
                   
                }),
            });

            const data = await response.json();
            if (response.ok) {
                console.log(data.url);
                navigate('/gamerun', { state: { iframeUrl: data.url } });
            } else {
                setError(data.message || 'An unexpected error occurred');
            }
        } catch (error) {
            setError('An unexpected error occurred');
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleProviderClick = (providerSystem) => {
      let updatedProviders = [];
    
      if (providerSystem === "ALL") {
        // If "ALL" is clicked, toggle all providers
        const shouldSelectAll = activeProvider.length !== providers.length;  // Check if not all are selected already
        updatedProviders = shouldSelectAll ? providers.map(provider => provider.platform) : [];  // Select all or deselect all
      } else {
        // If a specific provider is clicked, add or remove it from the activeProvider
        updatedProviders = activeProvider.includes(providerSystem)
          ? activeProvider.filter(provider => provider !== providerSystem)  // Remove the provider
          : [...activeProvider, providerSystem];  // Add the provider
      }
    
      setActiveProvider(updatedProviders);  // Update the activeProvider state
      fetchGames(updatedProviders);  // Fetch games based on the updated providers
    };
    
    
    
    
    const [imageLoaded, setImageLoaded] = useState({});

const handleImageError = (gameCode) => {
    setImageLoaded((prev) => ({
        ...prev,
        [gameCode]: false,
    }));
};

const handleImageLoad = (gameCode) => {
    setImageLoaded((prev) => ({
        ...prev,
        [gameCode]: true,
    }));
};
   

    const handleShowModal = () => setShowModal(true); 
    const handleCloseModal = () => setShowModal(false); 
    if(isLoading) {
      return(
        <div className="splash-screen">
        <img src={'./splash/mobile.gif'} alt="Loading..." />
      </div>
      );
    }
    

    
  return (
    <div className="main-router-wrapper">
      <MobileHeader />
      <div className="wrap">
        <div className="content">
          <div className="content-main">
            <div className="content-box">
              <div className="games">
                <div className="tab search-tab brand-tab">
                  <ul className="item-ani">
                  <li
                            onClick={() => handleProviderClick("ALL")}
                            className={`condition-groups ${activeProvider.length === providers.length ? 'active' : ''}`}  // Active if all providers are selected
                          >
                            <div
                              className="icon-all"
                              style={{
                                backgroundImage: 'url("./icons/icon-all-provider.svg")',
                              }}
                            />
                            <p>ALL</p>
                          </li>

                    {providers.map((provider) => (
                        <li
                          onClick={() => handleProviderClick(provider.platform)}
                          className={`condition-groups ${activeProvider.includes(provider.platform) ? 'active' : ''}`}
                          key={provider.platform}
                        >
                          <div className="condition-groups__inner">
                            <img
                              src={`./icons/platform/${provider.platform}.png`} 
                              alt={provider.platform}
                              loading="lazy"
                            />
                            <p>{provider.platform}</p>
                          </div>
                        </li>
                      ))}

                  </ul>
                  <div onClick={filtterOpen} className="btn search-btn">
                    <span
                      className="item-icon"
                      style={{
                        maskImage:
                          'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-search-type02.svg?v=1730201806250")',
                      }}
                    />
                  </div>
                </div>
                <div className="sort-bar">
                  <div className="sort-bar__title">
                    <span>{title}</span>
                  </div>
                  <div className="sort-bar__box">
                    <div className="sort-bar__btn">
                      <span>Filter</span>
                      <span
                        className="arrow"
                        style={{
                          maskImage:
                            'url("https://www.jeetbuzz.com/assets/images/icon-set/icon-arrow-type06.svg")',
                        }}
                      />
                    </div>
                    <ul className="sort-bar__select">
                      <li className="sort-bar__select__item" id="sort_recommend">
                        <span id="sort_recommend">Recommend</span>
                      </li>
                      <li className="sort-bar__select__item" id="sort_latest">
                        <span id="sort_latest">Latest</span>
                      </li>
                      <li className="sort-bar__select__item" id="sort_favorite">
                        <span id="sort_favorite">Favorite</span>
                      </li>
                      <li className="sort-bar__select__item" id="sort_aZ">
                        <span id="sort_aZ">A-Z</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="searchpage">
                  <div className="search-top-info">
                    <div onClick={filtterClose} className="back">
                      <span
                        className="item-icon"
                        style={{
                          maskImage:
                            'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1730201806250")',
                        }}
                      />
                      Back
                    </div>
                    <div
                      className="icon-search"
                      style={{
                        maskImage:
                          'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-search-type02.svg?v=1730201806250")',
                      }}
                    />
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      placeholder="Search Games"
                      name="search"
                      value={searchQuery}
                      onChange={handleSearch}
                      autocomplete="off"
                  />

                  </div>
                  <div className="searchpage-main">
                    <div className="search-checkbox-group check-group">
                      <h2>Providers</h2>
                      <ul>
                  <li  onClick={() => handleProviderClick('ALL')}>
                    <input
                      type="checkbox"
                      checked={activeProvider.length === providers.length} // Check if all providers are selected
                      // Handle "ALL" toggle
                    />
                    <label>
                      <span>All</span>
                    </label>
                  </li>
                  
                  {providers.map((provider, index) => (
                    <li onClick={() => handleProviderClick(provider.platform)} key={index}>
                      <input
                        type="checkbox"
                        checked={activeProvider.includes(provider.platform)} // Check if this provider is in activeProvider
                         // Toggle individual provider
                      />
                      <label>{provider.platform}</label>
                    </li>
                  ))}
                </ul>

                    </div>
                    
                  </div>
                  <div onClick={filtterClose} className="searchpage-bar">
                    <button className="button">Confirm</button>
                  </div>
                </div>
                <div className="games-main">
                      {filteredGames.length > 0 ? (
                        filteredGames.some((game) => imageLoaded[game.code] !== false) ? (
                          filteredGames.map(
                            (game) =>
                              imageLoaded[game.code] !== false && (
                                <div
                                  key={game.code}
                                  onClick={() => handleGameClick(game.code, game.platform, game.type)}
                                  className="games-box ng-trigger ng-trigger-staggerFadeAnimation"
                                >
                                  <div className="pic item-ani">
                                    <a>
                                      <img
                                        src={`./poster/${game.code}.png`}
                                        alt={game.name}
                                        loading="lazy"
                                        onLoad={() => handleImageLoad(game.code)}
                                        onError={() => handleImageError(game.code)}
                                      />
                                    </a>
                                  </div>
                                  <div className="text item-ani">
                                    <h3>{game.name}</h3>
                                    <div
                                      className="like"
                                      style={{
                                        backgroundImage:
                                          'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-heart-type02.svg?v=1730201806250")',
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                          )
                        ) : (
                          <NoData />
                        )
                      ) : (
                        <NoData/>
                      )}
                    </div>
                <div className="prompt">－end of page－</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isLoggedIn ? (
                  <Toolbar/>
              ) : (
                  <GuestToolbar/>
              )}
    </div>
  );
}

export default Games;
